import React, { useRef, useState } from 'react'
import { EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { Button, Typography } from '../../../../Components';
import { useShowToast } from '../../../../Utils/toast';

const ShareWithFriends = ({ url }) => {
    const urlInput = useRef(null);
    const showToast = useShowToast()
    const [embeddedURL, setEmbeddedURL] = useState(false)

    const IconComponent = ({ src, alt, label }) => (
        <div className="flex flex-col items-center space-y-2">
            <img src={src} alt={alt} className="size-6 md:size-7" />
            {label && <Typography variant="mediumText" className="tracking-tighter text-neutral-800" text={label} />}
        </div>
    );

    const socialurl = {
        url: url,
        quote: 'Hey! I have Donated my contribution to build a masjid ',
        via: '#support # Buildthemasjid #Help #dnation #charity',
        summary: 'I have shared a peice of help to build a masjid',
        hashtag: `#support # Buildthemasjid #Help #dnation #charity`,
        subject: `my Helping hand`,
        body: `I have shared a peice of help to build a masjid`,
        seperator: ``,
    }

    const onCopy = async () => {
        try {
            if (window.navigator.clipboard) {
                await window.navigator.clipboard.writeText(url)
                showToast({ message: "Copied to clipboard", variant: 'success' });
            } else {
                urlInput.current.select();
                document.execCommand("copy");
            }
        } catch (error) {
            console.error(error);
            showToast({ message: error.message, variant: 'error' });
        }
    }

    return (
        <div className="flex flex-col w-full gap-4 pt-5 border-t border-neutral-400">
            <div className="w-full pb-5 space-y-4 text-center border-b border-neutral-400">
                <Typography variant="heading5" className="text-neutral-1000 max-md:text-heading-6" text="Share with friends" />
                <Typography variant="mediumText" className="text-neutral-800 max-sm:px-9" text="Fundraisers shared on social networks raise up to 5x more" />
            </div>
            <div className="flex flex-col w-full gap-6">
                <div className="flex flex-wrap items-end justify-evenly md:justify-between gap-7.5">

                    <FacebookShareButton round={false} url={socialurl.url} className={"bg-facebook"}   >
                        <IconComponent src="/assets/images/facebook.svg" alt="Facebook" label="Facebook" />
                    </FacebookShareButton>
                    <TwitterShareButton title={socialurl?.quote} url={socialurl?.url} via={socialurl?.url} className={"bg-twitter"} related={[socialurl?.url]} hashtag={[socialurl?.url]} >
                        <IconComponent src="/assets/images/twitter.svg" alt="Twitter" label="Twitter" />
                    </TwitterShareButton>
                    <EmailShareButton subject={socialurl?.url} body={socialurl?.url} className="bg-neutral-1000" separator={socialurl?.url} >
                        <IconComponent src="/assets/images/mail.svg" alt="Email" label="Email" />
                    </EmailShareButton>
                    <WhatsappShareButton url={socialurl?.url} quote={socialurl?.url} className={"bg-whatsapp"} hashtag={socialurl?.url} >
                        <IconComponent src="/assets/images/whatsapp.svg" alt="whatsapp" label="whatsapp" />
                    </WhatsappShareButton>
                    <div role="button" onClick={() => setEmbeddedURL(!embeddedURL)}>
                        <IconComponent src="/assets/images/code.svg" alt="Embed HTML" label="Embed HTML" />
                    </div>
                </div>
                {embeddedURL && <div className="flex items-end w-full gap-4">
                    <div className="w-full">
                        <label className="block mb-2 font-normal text-sm/4 text-neutral-1000" htmlFor="site-url">Copy link</label>
                        <input id="site-url" value={url} className="input placeholder:text-neutral-1000" type='url' ref={urlInput} placeholder='https://buildthemasjid.com/7ds9c8a0ax' />
                    </div>
                    <Button
                        type="button"
                        loader={false}
                        onClick={onCopy}
                        loaderText=""
                        label="Copy"
                        iconClassName="hidden"
                        variant="primary"
                    />
                </div>}
            </div>
        </div>
    )
}

export default ShareWithFriends
