import React, { memo } from 'react';
import PropTypes from 'prop-types';

export const ProgressBar = memo(({ progress, textLabel, labelText, subLabel, value, className, labelTextClassName, progressClassName, thickness = "h-4" }) => {
 
    return (
        <>
            <div className={`flex items-center justify-between mb-4`}>
                <div className={`flex items-center gap-x-5.5 ${className}`}>
                    <h2 className={`font-bold text-heading-2 text-neutral-1000`}>{labelText}</h2>
                    <div className={`text-neutral-800 `}>
                        <p className={`font-normal text-sm/4 ${labelTextClassName}`}>{subLabel}</p>
                        <p className={`text-heading-5 ${labelTextClassName}`}>{textLabel}</p>
                    </div>
                </div>
                <p className={`text-heading-4 text-neutral-1000 ${progressClassName}`}>{value}</p>
            </div>
            <div className={`w-full ${thickness} rounded-full bg-neutral-300 `}>
                <div className={`${thickness} bg-green-300 rounded-full`} style={{ width: `${progress === '0%' ? '0px' : `${progress}%`}` }}></div>
            </div>
        </>
    );
});

ProgressBar.propTypes = {
    progress: PropTypes.string,
    textLabel: PropTypes.string,
    labelText: PropTypes.string,
    className: PropTypes.string,
    subLabel: PropTypes.string,
    labelTextClassName: PropTypes.string,
    progressClassName: PropTypes.string
};

ProgressBar.defaultProps = {
    progress: "",
    textLabel: "",
    labelText: "",
    className: "",
    subLabel: "",
    labelTextClassName: "",
    progressClassName: ""
};
