import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Button';
import { MyCart } from '../../Theme/icons';
import { useQuery } from '@apollo/client';
import { GET_CART_ITEMS } from '../../GraphQL/Queries/checkout';
import { updateCartItems } from '../../Redux/cartReducer';
import { uniqueElements } from '../../Utils/common';

const CartIcon = React.memo(({ onClick, className, count }) => {
    const dispatch = useDispatch();
    const { cartItems } = useSelector(state => state?.cart) || { cartItems: [] }
    const { user = null } = useSelector(state => state?.auth) || { user: null }
    const { data, loading } = useQuery(GET_CART_ITEMS, {
        fetchPolicy: 'no-cache',
        skip: user === null
    })
    const { getBasketItems = [] } = data || { getBasketItems: [] }
 
    useEffect(() => {
        if (getBasketItems?.length) {
            const basketItems = getBasketItems?.length && getBasketItems?.map(basketItem => ({
                ...basketItem,
                ...basketItem?.project,
                projectId:String(basketItem?.id),
                amount:parseFloat(basketItem?.amount),
              }))
         
            const uniqueCartItems = uniqueElements([...basketItems, ...cartItems])
           dispatch(updateCartItems(uniqueCartItems))
        }
        //eslint-disable-next-line 
    }, [getBasketItems])

    if (loading) {
        return
    }

    return (
        <div className={`text-center relative ${className}`}>
            <Button
                variant="primary-text"
                id="cart-button"
                label=""
                icon={<MyCart />}
                iconClassName=""
                labelClassName="hidden"
                className="!p-0 w-fit hover:!bg-transparent"
                onClick={onClick}
            />
            {cartItems?.length > 0 && (
                <div className="absolute inline-flex items-center justify-center w-5 h-5 font-bold text-white bg-red-300 rounded-full text-[10px] -top-2 -end-2">
                    {cartItems?.length}
                </div>
            )}
        </div>
    );
});

CartIcon.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    count: PropTypes.number,
};

CartIcon.defaultProps = {
    onClick: () => { },
    className: '',
    count: 2,
};

export default CartIcon;
