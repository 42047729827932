import React from 'react' 
import { useMutation } from '@apollo/client';
import { PAYPAL_CHECKOUT } from '../../../../GraphQL/Queries/payment';
import { useDispatch, } from 'react-redux';
import { useShowToast } from '../../../../Utils/toast';
import { updateCartItems } from '../../../../Redux/cartReducer';
import { SpinnerPrimaryIcon } from '../../../../Theme/icons';

export const PaypalCheckout = ({ updatePaymentStatus, isPaymentCompleted,  toggleModal }) => {
    const showToast = useShowToast();
    const dispatch = useDispatch()
    const [paypalCheckoutAPI, { loading }] = useMutation(PAYPAL_CHECKOUT)


    const callCheckoutAPI = async (user) => {
        try {
            const { data } = await paypalCheckoutAPI({
                variables: {
                    isAnonymous: Boolean(user === null)
                }
            })
            const { paypalCheckout = {} } = data || { paypalCheckout: {} }
            const { returnUrl, approvalUrl, result, error } = paypalCheckout;
            if (result) {
                returnUrl && window.open(approvalUrl, '_blank');
                // updatePaymentStatus({
                //     ...isPaymentCompleted,
                //     status: true,
                //     donationIds: paypalCheckout?.donationIds?.map(e => Number(e)),
                //     error: false,
                //     errorMessage: ''
                // })
                toggleModal()
                dispatch(updateCartItems([]))
                localStorage.removeItem('cart');
                
            } else {
                updatePaymentStatus({
                    ...isPaymentCompleted,
                    status: false,
                    donationIds: paypalCheckout?.donationIds?.map(e => Number(e)),
                    error: true,
                    errorMessage: error
                })
            }
            
        } catch (error) {
            showToast({ message: error.message, variant: 'error' })
            toggleModal()
        }
    }


    return (
        <div>
            {loading ? <Loader /> : <div className='h-fit w-[100px] m-auto' role="button" onClick={callCheckoutAPI}>
                <img src="/assets/images/paypal.svg" alt="paypal" className='w-full h-full object-contain' />
            </div>}

        </div>
    )
}

const Loader = () => {
    return (<div className='h-fit w-fit m-auto min-h-[20rem] flex flex-col items-center'>
        <SpinnerPrimaryIcon />
    </div>)
}
