import { Toast as FlowbiteToast } from 'flowbite-react';
import PropTypes from 'prop-types';
import { memo, useEffect } from 'react';
import customTheme from '../../Utils/flowbite-theme';

export const Toast =memo(({ message,variant,className, customTheme, onDismiss })=> {
    const toastClasses = {
      success: 'bg-green-300',
      error: 'bg-red-300',
      info: 'bg-primary-400',
      warning: 'bg-yellow-300',
      };
      useEffect(() => {
        const dismissTime = Number(process.env.REACT_APP_TOAST_DISMISS_TIME || 3000);
        const timer = setTimeout(() => {
          if (onDismiss) {
            onDismiss();
          }
        }, dismissTime ); 
    
        return () => clearTimeout(timer); 
      }, [onDismiss]);

      return (
          <div className="fixed top-4 right-4 z-50">
          <FlowbiteToast className={`text-white ${toastClasses[variant]} ${className}`} theme={customTheme}>
            <div className="text-medium-text font-medium">{message}</div>
            {/* <FlowbiteToast.Toggle onDismiss={onDismiss} /> */}
          </FlowbiteToast>
        </div>
      );
    });
    
    Toast.propTypes = {
      message: PropTypes.string,
      variant: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
      className: PropTypes.string,
      customTheme:PropTypes.object,
      onDismiss: PropTypes.func,
    };
    
    Toast.defaultProps = {
      message: '',
      variant: 'success',
      className:" ",
      customTheme:customTheme,
      onDismiss: () => {},
      duration: 2000,

    };


