import { AlertCircle } from "../Theme/icons";

export const formatNumberIntoKMB = (n) => {
  if (n >= 1000000) {
    return (n / 1000000)?.toFixed(2) + "M";
  } else if (n >= 1000) {
    return (n / 1000)?.toFixed(0) + "k";
  } else {
    return n?.toString();
  }
};

export const calculateCollectionPercentage = (totalAmount, collectedAmount) => {
  if (totalAmount <= 0 || null) {
    return 0.0;
  }
  const collectionPercentage =
    (parseFloat(collectedAmount) / parseFloat(totalAmount)) * 100;

  return collectionPercentage > 100 && collectedAmount !== 0
    ? 100
    : collectionPercentage;
};

export const calculateProcessPercentageCharge = (amount) => {
  return (amount * 3) / 100;
};

export const ErrorMessage = ({ error, touched }) => (
  <>
    {touched && Boolean(error) && (
      <div className="flex items-center gap-1 mt-2 text-left text-red-300 text-caption-text">
        <AlertCircle className="mr-1" />
        {touched && error}
      </div>
    )}
  </>
);

export const uniqueElements = (array) => {
  const uniqueValues = [];
  const newSet = new Set();
  array?.forEach((ele) => {
    const id = Number(ele?.["id"])
    if (!newSet.has(id)) {
      uniqueValues.push(ele);
      newSet.add(id);
    }
  });
  return uniqueValues;
};


export const currencies = ['USD', 'AUD', 'GBP', 'CSD', 'EUR', 'AED'];