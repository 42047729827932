import React, { useEffect, useState } from 'react'
import Button from '../Button';
import { Typography } from '../Typography';
import { AlertCircle, EditIcon, Spinner, TickIcon, TrashIcon } from '../../Theme/icons';
import { useShowToast } from '../../Utils/toast';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { UPDATE_BASKET_ITEMS } from '../../GraphQL/Queries/checkout';
import { updateCartItems } from '../../Redux/cartReducer';
import { amountValidationSchema } from '../../Utils/validation';
import currencies from "../../Utils/country-currencies.json"

export const CartItems = ({ cart, handleDelete, index, cartItems, setCartItems }) => {
    const showToast = useShowToast();
    const dispatch = useDispatch()
    const [error, setError] = useState("")
    const [amount, setAmount] = useState(cart?.amount || null);
    const [isEdit, setisEdit] = useState(false);
    const { user } = useSelector(state => state?.auth) || { user: null }
    const [updateCartAPI, { loading }] = useMutation(UPDATE_BASKET_ITEMS)

    const handleEdit = () => {
        setisEdit(!isEdit);
    }

    const handleChangeCartAmount = (e) => {
        setAmount(e.target.value);

    }

    const updateCartItemthroughAPI = async (cartItems, newItems) => {

        try {
            const { data } = await updateCartAPI({
                variables: {
                    projectId: cartItems?.id,
                    amount: cartItems?.amount,
                    isRecurring: cartItems?.isRecurring,
                    type: cartItems?.type,
                    quantity: cartItems?.quantity,
                    periodDays: cartItems?.periodDays,
                }
            })
            if (data) {
                showToast({ message: "Amount updated successfully", variant: 'success' })
                dispatch(updateCartItems(newItems))
                handleEdit()
            }

        } catch (error) {
            showToast({ message: error.message, variant: 'error' })
        }
    }



    const handleUpdate = async () => {
        await amountValidationSchema.validate({ amount }).then((res) => {
            setError("")
            const _cartItems = [...cartItems];
            _cartItems[index] = { ..._cartItems[index], amount: parseFloat(amount) }
            if (user === null) {
                localStorage.setItem('cart', JSON.stringify(_cartItems))
                handleEdit();
                setCartItems(_cartItems)
                dispatch(updateCartItems(_cartItems))
                showToast({ message: "Amount updated successfully", variant: 'success' })
            } else {
                updateCartItemthroughAPI({ ..._cartItems[index], amount: parseFloat(amount) }, _cartItems)
            }
        }).catch(err => {
            setError(err?.message)
        });
    }

    const getPeriod = () => {
        const periods = {
            30: "Monthly",
            7: "Weekly",
            365: "Yearly",
        }
        return periods[Number(cart?.periodDays)] || "One Time"
    }

    const projectCurrency = ({ currency }) => (currencies?.length && currencies?.find(country => country?.currency?.code === currency)?.currency?.symbol) || currency


    useEffect(() => {
        setAmount(cart?.amount)
    }, [cart])

    return (
        <li key={cart?.id + '--' + cart?.amount} className="relative p-2 overflow-hidden border rounded-lg border-neutral-300">
            <div className='flex items-center gap-3'>
                <div className='rounded-md size-14 min-w-14'>
                    <img className='object-cover w-full h-full rounded-md' src={cart?.images?.at(0)?.url || "/assets/images/no-image-available.svg"}
                        onError={
                            (e) => e.target.src = "/assets/images/no-image-available.svg"
                        }
                        alt={cart?.images?.at(0)?.url} />
                </div>
                <div className='w-full space-y-2'>
                    <Typography variant="smallText" text={cart?.name} className="font-bold leading-4 line-clamp-1 text-neutral-700 max-w-34 md:max-w-52 text-wrap" />
                    <div className='flex items-center justify-between'>
                        <div className='flex items-center'>
                            <div className='flex items-center h-8 px-2 border border-neutral-500 text-neutral-600 rounded-s-lg'>
                                <Typography variant="smallText" text={projectCurrency({ currency: cart?.currency })} className="font-bold mr-2 w-fit leading-4 line-clamp-1 text-neutral-900" />
                                {isEdit ?
                                    <div onClick={handleUpdate} role="button">
                                        {loading ? <Spinner /> : <TickIcon size={18} color="green" />}
                                    </div>
                                    : <div onClick={handleEdit} role="button">
                                        {loading ? <Spinner /> : <EditIcon size={18} />}
                                    </div>}
                            </div>
                            <div className='flex items-center h-8 px-2 border border-l-0 border-neutral-500 text-neutral-600 rounded-e-lg'>
                                {isEdit ? <input type="text" autoComplete='off' onChange={handleChangeCartAmount} name='amount' className='min-w-[100px] max-w-[100px] focus:outline-none' value={amount} /> :
                                    <Typography variant="smallText" text={amount} className="font-bold min-w-[100px] max-w-[100px] leading-4 line-clamp-1 text-neutral-900" />
                                }  </div>
                        </div>
                        <Button
                            type="button"
                            icon={<TrashIcon />}
                            labelClassName="hidden"
                            className="!p-1 text-primary-300 w-fit !rounded"
                            onClick={() => handleDelete(index)}

                        />
                    </div>
                    {error && (
                        <div className="flex items-center gap-1 mt-2 text-left text-red-300 text-caption-text">
                            <AlertCircle className="mr-1" />
                            {error}
                        </div>
                    )}
                </div>
            </div>
            <div className='absolute top-0 right-0 px-2 py-1 text-xs bg-yellow-400 md:px-3 rounded-se-lg rounded-es-md w-fit text-neutral-100'>{getPeriod()}</div>
        </li>

    )
}
