import { gql } from '@apollo/client'

const GET_PROJECT_OVERVIEW = gql`
query GetProjectQuery($getProjectOverviewId: Int!) {
  getProjectOverview(id: $getProjectOverviewId) {
    project {
      id
      name
      slug
      description
      status
      goalAmount
      goalAmountCurrency
      createdAt
      images {
        url
      }
      videoUrl
      ownerDetails {
        id
      }
      rewards {
        id
        name
        amount
        status
        imageUrl
        description
      }
      organiserDetails {
        firstName
        lastName
        displayName
        email
        role
        status
        profileImage
      }
      launchDate
      city
      country
    }
    projectUpdates {
      id
      status
      content
      images {
        url
        type
      }
      owner {
        firstName
        lastName
        displayName
        email
        profileImage
      }
      createdAt
    }
    recentDonations {
      id
      projectId
      stripePaymentId
      certificateUrl
      country
      zip
      amount
      amountCurrency
      zip
      firstName
      lastName
      email
      display
      donorDetails {
        firstName
        lastName
        displayName
        email
        role
        status
        profileImage
      }
    }
    topDonations {
      id
      projectId
      stripePaymentId
      certificateUrl
      country
      zip
      amount
      amountCurrency
      zip
      firstName
      lastName
      email
      display
      donorDetails {
        firstName
        lastName
        displayName
        email
        role
        status
        profileImage
      }
    }
    
    totalDonatedAmount
    totalDonationsCount
    totalDonationsThisWeek
    totalDonors
  }
}

  `

export default GET_PROJECT_OVERVIEW
