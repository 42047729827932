import { gql } from "@apollo/client";

export const GET_CLIENT_SECRET = gql`
  mutation Checkout($isAnonymous: Boolean!, $paymentMethodId: String) {
    checkout(isAnonymous: $isAnonymous, paymentMethodId: $paymentMethodId) {
      clientSecret
      id
      subscriptionId
      donationIds
    }
  }
`;

export const GET_UNKNOWN_CHECKOUT = gql`
  mutation CheckoutUnknown(
    $basketItems: [BasketItemInputForCheckoutUnknown!]!
    $firstName: String!
    $lastName: String!
    $email: String!
    $paymentGateway: String!
    $isAnonymous: Boolean
    $phone: String
    $address: String
    $state: String
    $currency: String
  ) {
    checkoutUnknown(
      basketItems: $basketItems
      firstName: $firstName
      lastName: $lastName
      email: $email
      paymentGateway: $paymentGateway
      isAnonymous: $isAnonymous
      phone: $phone
      address: $address
      state: $state
      currency: $currency
    ) {
      clientSecret
      paymentIntentId
      donationIds
      status
      approvalUrl
      returnUrl
      cancelUrl
      orderId
      result
      error
    }
  }
`;
export const GENERATE_PAYMENT_CERTIFICATE = gql`
  query Query($generateDonationCertificateId: Int) {
    generateDonationCertificate(id: $generateDonationCertificateId)
  }
`;
export const PAYPAL_CHECKOUT = gql`
  mutation PaypalCheckout($isAnonymous: Boolean!) {
    paypalCheckout(isAnonymous: $isAnonymous) {
      approvalUrl
      returnUrl
      cancelUrl
      orderId
      result
      error
      donationIds
    }
  }
`;

export const CREATE_WITHDRAWAL_REQUEST = gql`
  mutation CreateWithdrawalRequest(
    $requestedAmount: Float!
    $projectId: ID!
    $bankAccountId: ID!
  ) {
    createWithdrawalRequest(
      requestedAmount: $requestedAmount
      projectId: $projectId
      bankAccountId: $bankAccountId
    ) {
      id
      requestedAmount
      status
      projectId
      requestedBy
      bankAccountId
      createdAt
      updatedAt
    }
  }
`;

export const GET_USER_BANK_ACCOUNTS = gql`
  query GetActiveBankAccountsForUser {
  getActiveBankAccountsForUser {
    id
    userId
    bankCountry
    accountCurrency
    bankName
    branchName
    accountHolderName
    accountNumber
    status
  }
}
`;


export const ADD_BANK_ACCOUNT = gql`
  mutation AddBankAccount(
    $bankCountry: String!
    $accountCurrency: String!
    $bankName: String!
    $accountHolderName: String!
    $accountNumber: String!
    $branchName: String
  ) {
    addBankAccount(
      bankCountry: $bankCountry
      accountCurrency: $accountCurrency
      bankName: $bankName
      accountHolderName: $accountHolderName
      accountNumber: $accountNumber
      branchName: $branchName
    ) {
      id
      userId
      bankCountry
      accountCurrency
      bankName
      branchName
      accountHolderName
      accountNumber
      status
    }
  }
`;

export const DELETE_BANK_ACCOUNT = gql`
  mutation DeleteBankAccount($deleteBankAccountId: ID!) {
    deleteBankAccount(id: $deleteBankAccountId) {
      message
    }
  }
`;

export const GET_BANK_ACCOUNTS_BY_ID = gql`
  query Query($getBankAccountByIdId: ID!) {
    getBankAccountById(id: $getBankAccountByIdId) {
      id
      userId
      bankCountry
      accountCurrency
      bankName
      branchName
      accountHolderName
      accountNumber
      status
    }
  }
`;

export const UPDATE_BANK_ACCOUNT_BY_ID = gql`
  mutation Mutation(
    $updateBankAccountId: ID!
    $bankCountry: String
    $accountCurrency: String
    $bankName: String
    $branchName: String
    $accountHolderName: String
  ) {
    updateBankAccount(
      id: $updateBankAccountId
      bankCountry: $bankCountry
      accountCurrency: $accountCurrency
      bankName: $bankName
      branchName: $branchName
      accountHolderName: $accountHolderName
    ) {
      message
    }
  }
`;

export const GET_PAYMENTS = gql`
  query GetPayments(
    $projectId: Int
    $donationId: Int
    $page: Int
    $limit: Int
    $minDate: Date
    $maxDate: Date
    $country: String
    $sort: String
    $order: String
    $search: String
  ) {
    getPayments(
      projectId: $projectId
      donationId: $donationId
      page: $page
      limit: $limit
      minDate: $minDate
      maxDate: $maxDate
      country: $country
      sort: $sort
      order: $order
      search: $search
    ) {
      rows {
        id
        donationId
        updatedAt
        total
        conversionRate
        convertedAmount
        status
        project {
          id
          name
          slug
          description
          status
          goalAmount
          goalAmountCurrency
          rewards {
            id
            name
            amount
            status
            imageUrl
            description
          }
          organiserDetails {
            id
            firstName
            lastName
            displayName
            email
            role
            status
            profileImage
            defaultCurrency
          }
          ownerDetails {
            id
            firstName
            lastName
            displayName
            email
            role
            status
            profileImage
            defaultCurrency
          }
          launchDate
          city
          country
          isFeatured
          images {
            url
          }
          createdAt
          totalDonatedAmount
          videoUrl
          quickBookClassId
          quickBookClassRef
        }
        Donation {
          id
          projectId
          stripePaymentId
          certificateUrl
          country
          zip
          amount
          amountCurrency
          convertedAmount
          conversionRate
          firstName
          lastName
          email
          display
          isAnonymous
          status
          isRecurring
          periodDays
          stripeSubscriptionId
          orderId
          paypalOrderId
          quickBookReceiptId
          paymentMethodId
          paymentGateway
          quantity
          donatedAt
          createdAt
          donorDetails {
            id
            firstName
            lastName
            displayName
            email
            role
            status
            profileImage
            defaultCurrency
          }
          project {
            id
            name
            slug
            description
            status
            goalAmount
            goalAmountCurrency
            launchDate
            createdAt
          }
        }
      }
      total
    }
  }
`;
