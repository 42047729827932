import { gql } from "@apollo/client";

export const ADD_TO_BASKET = gql`
  mutation AddToBasket(
    $projectId: ID!
    $amount: Float!
    $isRecurring: Boolean!
    $type: String!
    $quantity: Int!
    $country: String
    $periodDays: Int
  ) {
    addToBasket(
      projectId: $projectId
      amount: $amount
      isRecurring: $isRecurring
      type: $type
      quantity: $quantity
      country: $country
      periodDays: $periodDays
    ) {
      basketItemId
    }
  }
`;

export const GET_CART_ITEMS = gql`
  query GetBasketItems {
    getBasketItems {
      id
      projectId
      userId
      total
      amount
      isRecurring
      periodDays
      type
      quantity
      rewardItemId
      phoneNumber
      country
      notes
      project {
        id
        name
        slug
        description
        status
        goalAmount
        goalAmountCurrency
        launchDate
        city
        country
        isFeatured
        createdAt
        createdBy
        organiser
        videoUrl
        quickBookClassId
        quickBookClassRef
        images {
          url
        }
      }
    }
  }
`;

export const DELETE_BASKET_ITEM = gql`
  mutation DeleteItemsFromBasket($projectId: String!) {
    deleteItemsFromBasket(projectId: $projectId) {
      message
    }
  }
`;

export const UPDATE_BASKET_ITEMS = gql`
  mutation UpdateBasket(
    $projectId: ID!
    $amount: Float!
    $isRecurring: Boolean!
    $type: String!
    $quantity: Int!
    $periodDays: Int
  ) {
    updateBasket(
      projectId: $projectId
      amount: $amount
      isRecurring: $isRecurring
      type: $type
      quantity: $quantity
      periodDays: $periodDays
    ) {
      basketItemId
    }
  }
`;
