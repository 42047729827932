import { createContext, useContext, useState } from 'react'
import { Toast } from '../Components'
import PropTypes from 'prop-types';

const ToastContext = createContext();

export const useShowToast = () => {
    return useContext(ToastContext);
}


export const ToastProvider = ({ children }) => {
    const [toastData, setToastData] = useState([]);
    const showToast = ({message, variant}) => {
        setToastData(td => [...td, {message, variant}])
    }
    const removeToast = index => {
        setToastData(td => td.filter((_,i) => i!==index));
    }
    return (
        <ToastContext.Provider value={showToast}>
            {children}
            {toastData.map((td, i) => (
                <Toast message={td.message} variant={td.variant} key={i} onDismiss={() => removeToast(i)} />
            ))}
        </ToastContext.Provider>
    )
}


ToastProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
