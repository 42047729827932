import { gql } from '@apollo/client';

export const GOOGLE_LOGIN_MUTATION = gql`
mutation GoogleAuth($email: String!, $firstName: String!, $lastName: String!) {
  googleAuth(email: $email, firstName: $firstName, lastName: $lastName) {
    token
    profile {
      firstName
      lastName
      displayName
      email
      role
      status
      profileImage
    }
  }
}
`;