import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import { Typography } from '../Typography';
import { ModalsComponent } from '../Modals'; 
import { DeleteModal } from './DeleteModal';
import { useNavigate } from 'react-router-dom';
import CheckoutModal from './../../Pages/Project/ProjectDetails/Checkout';
import { CartItems } from './CartItems';
import { TotalAmount } from './TotalAmount';
import { useShowToast } from '../../Utils/toast';
import { useDispatch, useSelector } from 'react-redux';
import { updateCartItems } from '../../Redux/cartReducer';
import { useMutation } from '@apollo/client';
import { DELETE_BASKET_ITEM } from '../../GraphQL/Queries/checkout';

export const EmptyCart = () => {
  return (
    <div className="text-center">
      <div className='px-16 py-10'>
        <img src="/assets/images/empty-cart.svg" alt="Empty cart" />
      </div>
      <Typography variant="heading4" text="Your Cart is Empty" />
    </div>
  );
};

const MyCartDrawer = React.memo(({ isOpen = false, onClose = () => null }) => {
  const showToast = useShowToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cartItems } = useSelector(state => state?.cart) || { cartItems: [] }
  const { user = null } = useSelector(state => state?.auth) || { user: null }

  const [deleteItemsFroMCart, { loading }] = useMutation(DELETE_BASKET_ITEM)

  const [deleteCardModal, setDeleteCardModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);

  
  const setCartItems = (carts) => {
    dispatch(updateCartItems(carts))
  }

  const removeItemFormBasket = async () => {
    const selectedId = String(cartItems?.[selectedIndex]?.id) || null;

    try {
      const { data } = await deleteItemsFroMCart({
        variables: {
          projectId: selectedId
        }
      })
      if (data) {
        showToast({ message: "Item removed from cart successfully", variant: 'success' })
        const _cartItems = [...cartItems]?.filter((elem, i) => i !== selectedIndex);
        setCartItems(_cartItems)
        setDeleteCardModal(false);
      }
    } catch (error) {
      showToast({ message: error.message, variant: 'error' })
    }
  }

  const handleRemoveElementFromCart = () => {
    if (user === null) {
      const _cartItems = [...cartItems]?.filter((elem, i) => i !== selectedIndex);
      localStorage.setItem('cart', JSON.stringify(_cartItems))
      showToast({ message: "Item removed from cart successfully", variant: 'success' })
      setCartItems(_cartItems)
      setDeleteCardModal(false);
    } else {
      removeItemFormBasket()
    }
  }

  const handleViewCart = () => {
    navigate('/mybasket')
  }

  const toggleCheckoutModal = () => setIsCheckoutModalOpen(!isCheckoutModalOpen);

  const handleDelete = (index) => {
    setSelectedIndex(index);
    setDeleteCardModal(true)
  }

  const handleCheckout = (e) => {
    e.preventDefault();
    e.persist()
    onClose();
     toggleCheckoutModal();
  }

  useEffect(() => {
    let timeoutId;

    if (isOpen) {
      timeoutId = setTimeout(() => {
        document.body.style.overflow = 'hidden';
      }, 100);
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      clearTimeout(timeoutId);
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);



  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-0 right-0 z-30 flex items-start justify-end h-dvh bg-gray-900/40">
          <div
            id="drawer-right-example"
            className={`fixed top-0 right-0 z-40 h-screen p-6 transition-transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} bg-white w-80 md:w-100`}
            tabIndex="-1"
            aria-labelledby="drawer-right-label"
          >
            <div className='h-full space-y-6'>
              <div className="flex items-center justify-between">
                <h5 id="drawer-right-label" className="inline-flex items-center text-lg font-semibold text-gray-500 md:text-heading-5 dark:text-gray-400">
                  My Cart
                </h5>
                <button
                  type="button"
                  onClick={onClose}
                  aria-controls="drawer-right-example"
                  className="inline-flex items-center justify-center w-8 h-8 text-sm bg-transparent rounded-lg text-neutral-1000 hover:bg-gray-200"
                >
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                  <span className="sr-only">Close menu</span>
                </button>
              </div>
              <div className='h-auto'>
                {cartItems?.length === 0 ? (
                  <div className='flex items-center justify-center h-auto'>
                    <EmptyCart />
                  </div>
                ) : (
                  <div className='flex flex-col justify-between h-full gap-7'>
                    <ul className='overflow-y-auto min-h-vh-minus-425 max-h-vh-minus-425 space-y-2.5'>
                      {!!cartItems?.length &&
                        cartItems?.map((cart, index) =>
                          <CartItems key={index} cart={cart} index={index}
                            setSelectedIndex={setSelectedIndex} handleDelete={handleDelete}
                            cartItems={cartItems} setCartItems={setCartItems}
                            setDeleteCardModal={setDeleteCardModal} />
                        )}
                    </ul>
                    <TotalAmount cartItems={cartItems} setCartItems={setCartItems} />
                    <div className='space-y-5'>
                      <Button
                        type="button"
                        loader={false}
                        loaderText=""
                        label="View Cart"
                        iconClassName="hidden"
                        loaderClassName=""
                        className="w-full"
                        variant='primary-outline'
                        onClick={handleViewCart}
                      />
                      <Button
                        type="button"
                        loader={false}
                        loaderText=""
                        label="Checkout"
                        iconClassName="hidden"
                        loaderClassName=""
                        className="w-full"
                        variant='primary'
                        onClick={handleCheckout}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

          </div>
        </div>
      )}

      <ModalsComponent
        showModal={deleteCardModal}
        toggleModal={() => setDeleteCardModal(false)}
        content={<DeleteModal selectedIndex={selectedIndex} loading={loading}
          setSelectedIndex={setSelectedIndex} handleRemoveElementFromCart={handleRemoveElementFromCart} />}
      />
      <CheckoutModal project={{}} cartType="multiple" hidePrevButton={true} activeStep={2} showModal={isCheckoutModalOpen} toggleModal={toggleCheckoutModal} />
    </>
  );
});

MyCartDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default MyCartDrawer;
