import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import projectOverviewRoute from './projectOverviewRoute';
import adminRoutes from './adminRoutes';

const ReusableComponents = lazy(() => import('../AllComponents/index'));
const Login = lazy(() => import('../Pages/Login/index'));
const SignUp = lazy(() => import('../Pages/SignUp/index'));
const ForgotPassword = lazy(() => import('../Pages/ForgotPassword/index'));
const ResetPassword = lazy(() => import('../Pages/ResetPassword/index'));
const VerifyAccount = lazy(() => import('../Pages/VerifyAccount/index'));
const VerifyOTP = lazy(() => import('../Pages/VerifyResetPasswordOTP/index'));
const MyAccount = lazy(() => import('../Pages/UserSettings/MyAccount/index'));
const MyProjects = lazy(() => import('../Pages/UserSettings/MyProjects/index'));
const MyDonations = lazy(() => import('../Pages/UserSettings/MyDonations/index'));
const MyCards = lazy(() => import('../Pages/UserSettings/MyCards/index'));
const CreateProject = lazy(() => import('../Pages/CreateProject/index'));
// const Home = lazy(() => import("../Components/HomePage/index"))
const EditOverviewPage = lazy(() => import('../Pages/Project/Overview/EditOverviewPage/index'));
const ProjectDetails = lazy(() => import('../Pages/Project/ProjectDetails/index'))
const AdminBlogs = lazy(() => import('../Pages/AdminPanel/AdminDashboard/AdminBlogs/index'));
const ProjectListingPage = lazy(() => import('../Pages/ProjectListing/index'));
const BlogListingPage = lazy(() => import('../Pages/BlogListing/index'));
const BlogDetailsPage = lazy(() => import('../Pages/BlogDetails/index'));
const HomePage = lazy(() => import('../Pages/HomePage/index'));
const SignUpVerify = lazy(() => import('../Pages/SignUpVerification/index'));
const CheckoutCart = lazy(() => import('../Pages/Project/ProjectDetails/Checkout/CheckoutCart/index'));
const PageNotFound = lazy(() => import('../Pages/ErrorPages/PageNotFound/index'));
const Unauthenticated = lazy(() => import('../Pages/ErrorPages/Unauthenticated/index'));

const allRoutes = [
	projectOverviewRoute,
    {
        path: '/components',
        element: <ReusableComponents />,
        title: 'Reusable Components',
    },
    {
        path: '/login',
        element: <Login />,
        title: 'Login',
        redirectUser: '/home'
    },
    {
        path: '/signup',
        element: <SignUp />,
        title: 'SignUp',
        redirectUser: '/home'
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword />,
        title: 'Forgot Password',
        redirectUser: '/home'
    },
    {
        path: "/reset-password",
        element: <ResetPassword />,
        title: 'Reset Password',
        redirectUser: '/home'
    },
    {
        path: "/verify-account",
        element: <VerifyAccount />,
        title: 'Verify Account',
        redirectUser: '/home'
    },
    {
        path: "/account",
        element: <MyAccount />,
        title: 'Account',
        redirectNoAuth: '/login',
    },
    {
        path: "/my-projects",
        element: <MyProjects />,
        title: 'My Projects',
    },
    {
        path: "/my-donations",
        element: <MyDonations />,
        title: 'My Donations',
    },
    {
        path: "/my-cards",
        element: <MyCards />,
        title: 'My Cards',
    },
    {
        path: "/create-project",
        element: <CreateProject />,
        title: 'Create Project',
        redirectNoAuth: '/login'
    },
    
    {
        path: "/home",
        element: <HomePage />,
        title: 'home',
    },
    {
        path: "/verify-otp",
        element: <VerifyOTP />,
        title: 'home',
        redirectUser: '/home',
    },
    {
        path: "/edit-project",
        element: <EditOverviewPage />,
        title: 'Project Overview - Edit',

    },
    {
        path: "/project-details",
        element: <ProjectDetails />,
        title: 'Project Details',
    },
    {
        path: "/admin-blogs",
        element: <AdminBlogs />,
        title: 'Admin Blogs',
    },
    {
        path: "/project-listing",
        element: <ProjectListingPage />,
        title: 'Projects',
    },
    {
        path: "/blogs",
        element: <BlogListingPage />,
        title: 'Blogs',
    },
    {
        path: "/blog-details",
        element: <BlogDetailsPage />,
        title: 'Blog Details',
    },
    {
        path: "/signup-verify",
        element: <SignUpVerify />,
        title: 'signup verify',
    },
    {
        path: "/mybasket",
        element: <CheckoutCart />,
        title: 'My Basket',
    },
    {
        path: "/401",
        element: <Unauthenticated />,
        title: '401',
    },
    {
        path: "/admin",
        children: adminRoutes,
    },
    {
        path: "/",
		exact: true,
        element: <Navigate to="/home" replace />,
    }, {
		path: "*",
		element: <PageNotFound />
	}
]

export default allRoutes
